import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"

const Internal = props => {
  return (
    <Layout location={props.location}>
      <SEO title="Internal View" />
      <div className="container">
        <h1>Internal View</h1>
      </div>
    </Layout>
  )
}

export default Internal

